/* .carousel-root {
	max-height: 400px !important;
	width: auto;
} */

.carousel .slide img {
	max-height: 500px; /* change this to whatever you want */
	width: auto;
}

.carousel .thumb img {
	width: 100% !important;
	height: 100% !important;
}
